@import "../../styles/functions";

.footer {
  padding: rem(95px) 0 rem(115px) 0;
  display: flex;
  justify-content: space-between;

  border-top: rem(2px) solid var(--color-white);

  @media (max-width: $mobile) {
    position: relative;
    
    margin-top: rem(40px);
    padding: rem(48px) rem(30px) rem(50px);
    flex-direction: column;
    align-items: center;
  }
}

.wrapper {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.top {
  position: absolute;
  top: rem(-40px);

  display: none;
  width: 100%;
  left: 0;
  right: 0;
  height: rem(40px);
  justify-content: center;
  align-items: center;

  font-size: rem(25px);
  font-family: "Malayalam", sans-serif;
  color: var(--color-white);
  font-weight: bold;

  border-top: rem(2px) solid var(--color-white);
  cursor: pointer;
  user-select: none;

  @media (max-width: $mobile) {
    display: flex;
  }
}

.text {
  margin-bottom: rem(40px);

  font-family: 'Circe', sans-serif;
  font-size: rem(23px);
  line-height: rem(34px);
  letter-spacing: 0.07em;
  color: rgba(255, 255, 255, 0.5);
  white-space: nowrap;
  text-align: center;

  @media (max-width: $mobile) {
    font-size: rem(15px);
    line-height: rem(22px);
  }
}
