@import "../../styles/functions";

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  padding: rem(45px) 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $mobile) {
    padding: rem(10px) rem(25px);
    justify-content: flex-start;
  }
}