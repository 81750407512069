@import "../../styles/functions";

.lang {
  font-family: 'Circe', sans-serif;
  font-weight: bold;
  font-size: rem(18px);
  line-height: rem(27px);
  letter-spacing: 0.07em;

  color: #5E5E5E;

  & span, & a {
    color: var(--color-black);
    transition: .3s;
  }

  & a:hover {
    color: var(--color-blue);
  }
}