@import "../../styles/functions";

.contacts {
  padding-top: rem(89px);

  @media (max-width: $mobile) {
    padding-top: rem(59px);
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.link {
  font-family: 'Circe', sans-serif;
  font-size: rem(23px);
  line-height: rem(34px);
  text-align: center;
  letter-spacing: 0.07em;
  text-shadow: 0 rem(4px) rem(4px) rgba(0, 0, 0, 0.5);

  @media (max-width: $mobile) {
    font-size: rem(14px);
    line-height: rem(20px);
  }

  // & span:first-of-type {
  //   color: #676767;
  // }

  .icon{
    width: rem(19px);
    aspect-ratio: 1;
    margin-right: 8px;

    @media (max-width: $mobile) {
      width: rem(11px);
      margin-right: 6px;
    }
  }

  .icon2{
    width: rem(23px);
    aspect-ratio: 1;
    margin-right: 8px;
    transform: translateY(4.5px);

    @media (max-width: $mobile) {
      width: rem(15px);
      margin-right: 6px;
      transform: translateY(4px);
    }
  }
}

.text{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Malayalam', sans-serif;
  font-size: rem(30px);
  line-height: rem(42px);
  letter-spacing: 0.09em;  
  color: var(--color-white);
  

  @media (max-width: $mobile) {
    text-align: center;   
    font-size: rem(12px);
    line-height: rem(17px); 
  }
}

.dazzmail{   
  font-family: "Malayalam", sans-serif;
  font-size: rem(30px);
  line-height: rem(42px);
  letter-spacing: 0.09em;
  color: var(--color-white);
  margin-top: rem(40px);
  margin-bottom: rem(100px);  

  @media (max-width: $mobile) {
    font-size: rem(16px);    
    margin-top: rem(26px);
    margin-bottom: rem(40px);
  }
}

.maps {
  position: relative;

  display: flex;
  flex-direction: column;
  width: rem(1542px);
  padding-bottom: rem(190px);
  margin-top: rem(80px);

  @media (max-width: $mobile) {
    width: 100%;
    padding-bottom: 0;
    margin-top: 0;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    display: block;
    width: rem(2px);
    height: 100%;

    background-color: var(--color-divider);

    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.map {
  width: rem(640px);
  margin-bottom: rem(30px);

  animation-duration: 2s!important;

  @media (max-width: $mobile) {
    position: relative;
    width: 100%;
    margin-bottom: rem(20px);
  }

  &:before, &:after {
    @media (max-width: $mobile) {
      content: '';
      display: block;

      background-color: var(--color-divider);
    }
  }

  &:after {
    @media (max-width: $mobile) {
      margin: rem(48px) auto 0;
      width: rem(2px);
      height: rem(70px);
    }
  }

  &:before {
    @media (max-width: $mobile) {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: rem(30px);

      width: rem(7px);
      height: rem(7px);

      box-shadow: 0 rem(4px) rem(4px) rgba(0, 0, 0, 0.5);
      background-color: var(--color-divider);
      border-radius: 50%;
    }
  }


  &:last-of-type {
    margin-bottom: 0;
  }

  &:last-of-type:before {
    display: none;
  }

  &:last-of-type:after {
    margin-top: rem(71px);
    height: rem(100px);
    background-color: var(--color-white);
  }

  &.disabled {
    pointer-events: none
  }
}

.map.disabled {

  & .block:before {
    background: rgba(0, 0, 0, 0.3);
    box-shadow: none;
  }

  & .block:after {
    display: none;
  }
}

.map:nth-of-type(2) {
  margin-left: auto;
}

.title {
  margin-bottom: rem(70px);
  transform: translateY(rem(30px));

  text-align: center;
  font-family: 'Malayalam', sans-serif;
  font-weight: bold;
  font-size: rem(30px);
  line-height: rem(42px);
  letter-spacing: 0.07em;
  text-shadow: 0 rem(4px) rem(4px) rgba(0, 0, 0, 0.5);

  @media (max-width: $mobile) {
    margin-bottom: rem(63px);

    font-size: rem(16px);
    line-height: rem(23px);
  }
}

.block {
  position: relative;

  width: rem(640px);
  height: rem(343px);

  cursor: pointer;

  @media (max-width: $mobile) {
    width: 100%;
    height: rem(454px);
    display: none;
  }

  &:before, &:after {
    position: absolute;
    content: '';

    display: block;
  }

  &:before {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;

    width: 100%;
    height: 100%;

    background: rgba(0, 0, 0, 0.1);
    transition: .3s;
  }

  &:hover:before {
    background: none!important;
  }

  &:after {
    bottom: rem(19.84px);
    right: rem(20px);
    z-index: 2;

    width: rem(29px);
    height: rem(40.16px);

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../resources/icons/finger.svg");

    @media (max-width: $mobile) {
      right: rem(21px)!important;
    }
  }

  &.mobile {
    display: none;

    @media (max-width: $mobile) {
      display: block;
    }
  }

  & img {
    width: 100%;
    height: 100%;

    @media (max-width: $mobile) {
      object-fit: cover;
      object-position: center;
    }
  }
}

.map:nth-of-type(1) .block:before,
.map:nth-of-type(3) .block:before {
  box-shadow: inset rem(-200px) 0 rem(250px) rgba(0, 0, 0, 0.5);

  @media (max-width: $mobile) {
    box-shadow: inset rem(100px) rem(-50px) rem(250px) rgba(0, 0, 0, 0.5);
  }
}

.map:nth-of-type(2) .block:before {
  box-shadow: inset rem(200px) rem(-50px) rem(250px) rgba(0, 0, 0, 0.5);

  @media (max-width: $mobile) {
    box-shadow: inset rem(100px) rem(-50px) rem(250px) rgba(0, 0, 0, 0.5);
  }
}

.map:nth-of-type(2) .block:after {
  left: rem(20px);
  right: auto;

  @media (max-width: $mobile) {
    right: rem(20px);
    left: auto;
  }
}

.info {
  position: relative;

  margin-bottom: rem(60px);
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: $mobile) {
    margin-bottom: rem(52px);
  }

  & .before {
    content: '';
    position: absolute;
    right: rem(-135px);
    bottom: rem(10px);

    display: block;
    width: rem(8px);
    height: rem(8px);

    border-radius: 50%;
    background-color: var(--color-divider);
    box-shadow: 0 rem(4px) rem(4px) rgba(0, 0, 0, 0.5);
    animation-duration: 2s!important;

    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.map:nth-of-type(2) .info {

  & .before {
    right: auto;
    left: rem(-135px);
  }
}

.design {
  padding: rem(120px) 0 rem(30px);
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: $mobile) {
    padding: rem(70px) 0 rem(82px);
  }
}

.slogan {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dazzz {
  margin-bottom: rem(90px);

  font-family: 'Malayalam', sans-serif;
  font-size: rem(46px);
  line-height: rem(65px);
  text-align: center;
  letter-spacing: 0.09em;

  @media (max-width: $mobile) {
    margin-bottom: 0;
    padding: rem(3px) 0 0;
    width: rem(170px);

    font-size: rem(22px);
    line-height: rem(31px);
    text-align: center;
    letter-spacing: 0.07em;
  }

  & span {
    color: rgba(255, 255, 255, 0.5);
  }
}

.function {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.func {
  position: relative;
  display: inline-block;

  font-family: 'Malayalam', sans-serif;
  font-size: rem(45px);
  line-height: rem(63px);
  text-align: center;
  letter-spacing: 0.07em;

  @media (max-width: $mobile) {
    font-size: rem(24px);
    line-height: rem(34px);
    font-weight: bold;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    height: rem(2px);
    width: 100%;
    display: block;

    background-color: var(--color-white);
  }
}