@import "../../styles/functions";

.loader {
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
  position: fixed;

  transition: .5s;

  z-index: 100;
  background-color: black;
}

.loader .caption {
  margin: 0 auto;
}

.cube-loader {
  width: 73px;
  height: 73px;
  margin: 0 auto;
  margin-top: 49px;
  position: relative;
  transform: rotateZ(45deg);
}

.loader .cube {
  position: relative;
  transform: scale(1.1);
  width: 50%;
  height: 50%;
  float: left;
}
.loader .cube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  animation: cube-loader 2.76s infinite linear both;
  transform-origin: 100% 100%;
}
.loader .loader-2 {
  transform: scale(1.1) rotateZ(90deg);
}
.loader .loader-3 {
  transform: scale(1.1) rotateZ(180deg);
}
.loader .loader-4 {
  transform: scale(1.1) rotateZ(270deg);
}
.loader .loader-2:before {
  animation-delay: 0.35s;
}
.loader .loader-3:before {
  animation-delay: 0.69s;
}
.loader .loader-4:before {
  animation-delay: 1.04s;
}

@keyframes cube-loader {
  0%, 10% {
    transform: perspective(136px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(136px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    transform: perspective(136px) rotateY(180deg);
    opacity: 0;
  }
}
