@import "../../styles/functions";

.control {
  display: inline-block;
  margin-bottom: rem(100px);

  font-family: 'Malayalam', sans-serif;
  font-size: rem(30px);
  line-height: rem(42px);
  letter-spacing: 0.09em;
  text-transform: uppercase;
  text-shadow: 0 rem(4px) rem(4px) rgba(0, 0, 0, 0.5);
  user-select: none;


  @media (max-width: $mobile) {
    margin-bottom: rem(40px);

    font-size: rem(18px);
    line-height: rem(25px);
  }
}