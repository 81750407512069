@import "../../styles/functions";

.who {
  position: relative;
  padding: rem(87px) 0 rem(180px);

  @media (max-width: $mobile) {
    padding: rem(57px) rem(20px) rem(47px) rem(20px);
  }
}

.desc {
  margin-bottom: rem(173px);

  @media (max-width: $mobile) {
    margin-bottom: rem(79px);

    font-size: rem(15px) !important;
    line-height: rem(22px) !important;
    text-align: justify!important;
  }
}

.list {
  position: relative;
  padding-top: rem(10px);

  @media (max-width: $mobile) {
    padding-top: 0;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    display: block;
    width: rem(2px);
    height: calc(100% - rem(65px));

    background-color: var(--color-divider);

    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.desc, .list {
  width: rem(860px);

  @media (max-width: $mobile) {
    width: 100%;
  }
}

.test {
  animation-duration: 2s!important;
}

.item {
  position: relative;

  width: rem(352px);
  margin-bottom: rem(20px);

  animation-duration: 2s!important;

  &:first-of-type {
    margin-bottom: rem(55px);

    @media (max-width: $mobile) {
      margin-bottom: rem(28px);
    }
  }

  &:last-of-type {
    margin-bottom: 0;

    @media (max-width: $mobile) {
      margin-bottom: 0;
    }
  }

  @media (max-width: $mobile) {
    width: 100%;
    margin-bottom: rem(28px);
  }

  & .before {
    content: '';
    position: absolute;
    right: rem(-82px);
    top: rem(17px);

    display: block;
    width: rem(8px);
    height: rem(8px);

    border-radius: 50%;
    background-color: var(--color-divider);
    box-shadow: 0 rem(4px) rem(4px) rgba(0, 0, 0, 0.5);
    animation-duration: 2s!important;

    @media (max-width: $mobile) {
      top: auto;
      bottom: rem(21px);
      right: calc(50% - rem(7px));
      transform: translateX(-50%);

      width: rem(7px);
      height: rem(7px);
    }
  }

  &:after {
    content: '';

    display: none;
    margin: rem(30px) auto 0;
    height: rem(50px);
    width: rem(2px);
    background-color: var(--color-divider);

    @media (max-width: $mobile) {
      display: block;
      margin: rem(23px) auto 0;
    }
  }

  &:last-of-type:before,
  &:last-of-type:after {
    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.title {
  margin-bottom: rem(33px);

  font-family: 'Malayalam', sans-serif;
  font-size: rem(30px);
  line-height: rem(42px);
  letter-spacing: 0.07em;

  @media (max-width: $mobile) {
    margin-bottom: rem(10px);

    font-size: rem(16px);
    line-height: rem(23px);
    text-align: center!important;
  }
}

.item:nth-of-type(2), .item:nth-of-type(4) {
  margin-left: auto;

  @media (max-width: $mobile) {
    margin-left: 0;
  }

  & .before {
    left: rem(-82px);

    @media (max-width: $mobile) {
      left: auto;
    }
  }
}

.text {
  color: #5E5E5E;

  transition: 3s color;

  font-size: rem(23px);
  line-height: rem(34px) !important;

  @media (max-width: $mobile) {
    text-align: justify!important;
    font-size: rem(15px) !important;
    line-height: rem(22px) !important;
    letter-spacing: 0.07em;
  }
}

.item:nth-of-type(1), .item:nth-of-type(3) {

  & .title {
    text-align: right;
  }

  & .text {
    text-align: right;
  }
}

.item:nth-of-type(2), .item:nth-of-type(4) {

  & .title {
    text-align: left;
  }

  & .text {
    text-align: left;
  }
}

.item:last-of-type .before {
  @media (max-width: $mobile) {
    display: none;
  }
}