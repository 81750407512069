@import "../../styles/functions";

.list {
  display: flex;
}

.item {
  margin-right: rem(20px);

  @media (max-width: $mobile) {
    margin-right: rem(12px);
  }

  &:last-of-type {
    margin-right: 0;
  }
}

.link {
  display: block;
  width: rem(37px);
  height: rem(37px);

  @media (max-width: $mobile) {
    width: rem(30px);
    height: rem(30px);
  }

  & svg {
    width: 100%;
    height: 100%;

    transition: fill 0.3s;
  }

  &:hover svg {
    fill: var(--color-blue);
  }
}

.footer .link {
  @media (max-width: $mobile) {
    width: rem(30px);
    height: rem(30px);
  }

  & svg {
    fill: var(--color-white);
  }

  &:hover svg {
    fill: var(--color-blue);
    filter: invert(0);
  }
}

.sticky {
  position: fixed;
  z-index: 10;
  left: rem(70px);
  top: calc(50% + rem(5px));
  transform: translateY(-50%);

  flex-direction: column;

  will-change: filter;
  filter: drop-shadow(0 0 0 var(--color-black));

  @media (max-width: $mobile) {
    display: none;
  }

  & svg {
    fill: gray;
  }

  & .item {
    margin-bottom: rem(22px);
    width: rem(32.7px);
    height: rem(32.23px);

    transition: 0.3s;
    &:hover svg {
      fill: var(--color-white);
    }
  }
  .hoverDark {
    &:hover svg {
      fill: var(--color-yellow-green);
    }
  }
}

.link:hover .text {
  color: var(--color-white);
}

.text {
  display: none;
  margin-left: rem(19.98px);
  padding-top: rem(3px);

  font-family: "Circe", sans-serif;
  font-size: rem(23px);
  line-height: rem(34px);
  letter-spacing: 0.07em;
  color: rgba(255, 255, 255, 0.5);

  transition: 0.3s;
}

.footer {
  @media (max-width: $mobile) {
    display: flex;
    justify-content: center;
  }

  & .text {
    display: block;

    @media (max-width: $mobile) {
      display: none;
    }
  }

  & .item {
    margin-right: rem(60px);

    @media (max-width: $mobile) {
      margin-right: rem(43px);
    }
  }

  & .item:last-of-type {
    margin-right: 0;
  }

  & .link {
    width: auto;
    display: flex;
    align-items: center;
  }

  & svg {
    flex-shrink: 0;
    width: auto;
  }
}

.circle {  
  width: rem(100px);
  height: rem(100px);
  border-radius: 50%;
  margin-left: rem(-34px);
  margin-top: rem(8px);  
  background-color: #8fbf21;
}

.circle.transparent{
  background-color: transparent;
}

.career {
  color: var(--color-white);
  font-family: "Malayalam";
  padding-left: rem(36px);
  padding-top: rem(15px);
  font-size: rem(20px);
  line-height: 122%;
  letter-spacing: 1px;
  font-weight: 900;
}

.gray{
  color: gray;
  &:hover{
    color: var(--color-white);
  }
}
.black {
  color: var(--color-black);
}
