@import "../../styles/functions";

.sticky {
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;

  display: none;
  height: rem(110px);
  width: rem(130px);

  transition: all 0.5s ease;
  animation: smoothScroll 1s forwards;

  @media (max-width: $mobile) {
    left: 0;

    height: rem(45px);
    width: 100%;

    background-color: var(--color-black);
    border-bottom: rem(1px) solid var(--color-white);
  }

  &.active {
    display: flex;
  }
}

.logo {
  position: absolute;
  left: rem(10px);
  top: rem(8px);
  display: none;

  width: rem(167px);
  height: rem(33px);

  filter: drop-shadow(0 rem(4px) rem(4px) rgba(0, 0, 0, 0.25));

  @media (max-width: $mobile) {
    display: block;
  }

  & img {
    width: 100%;
    height: 100%;
  }
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-142px);
  }

  100% {
    transform: translateY(0px);
  }
}