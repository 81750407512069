@import "../../styles/functions";

.link {
  display: inline-block;
  margin-bottom: rem(34px);
  float: left;

  font-family: 'Circe', sans-serif;
  font-weight: bold;
  font-size: rem(23px);
  line-height: rem(34px);
  letter-spacing: 0.07em;
  color: var(--color-black);
  cursor: pointer;
  transition: .3s;

  @media (max-width: $mobile) {
    margin-bottom: rem(20px);
  }

  &:hover {
    color: var(--color-blue);
  }
}

.footer .link {
  margin-bottom: rem(20px);

  font-family: 'Malayalam', sans-serif;
  font-size: rem(20px);
  line-height: rem(28px);
  color: var(--color-white);
  font-weight: normal;

  &:hover {
    color: var(--color-blue);
  }

  @media (max-width: $mobile) {
    font-size: rem(12px);
    line-height: rem(17px);
  }
}

.list {
  margin-bottom: rem(50px);

  @media (max-width: $mobile) {
    margin-bottom: rem(40px);
  }

  &.footer a.link,
  &.footer div.link:last-of-type {
    display: none;
  }

  &:after {
    content: "";
    clear: both;
    display: table;
  }
}

.footer.list {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;

  @media (max-width: $mobile) {
    align-items: center;
  }
}

// a.link {
//   @media (max-width: $mobile) {
//     display: none;
//   }
// }