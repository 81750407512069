@import "../../styles/functions";

.logo {
  width: rem(350px);
  height: rem(42px);

  @media (max-width: $mobile) {
    width: rem(133px);
    height: rem(86px);

    filter: drop-shadow(0 rem(4px) rem(4px) rgba(0, 0, 0, 0.25));
  }

  & svg, & img, & div {
    width: 100%;
    height: 100%;
  }

}
.invert{
  filter: invert(1);
}