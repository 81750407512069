@import "../../styles/functions";

.burger {
  position: absolute;
  top: rem(50px);
  right: rem(60px);

  width: rem(53px);
  height: rem(35px);
  padding: rem(10px);

  cursor: pointer;

  will-change: filter;
  filter: drop-shadow(0 0 0 var(--color-black));

  @media (max-width: $mobile) {
    top: rem(20px);
    right: rem(20px);
  }

  &.sticky {
    top: rem(45px);
    opacity: 1;

    @media (max-width: $mobile) {
      top: rem(6px);
      right: rem(20px);

      opacity: 1;
    }
  }

  &:before, &:after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    display: block;
    height: rem(5px);

    background-color: gray;
  }

  &:before {
    top: rem(10px);

    width: rem(33px);
  }

  &:after {
    bottom: rem(10px);

    width: rem(23px);
  }
}