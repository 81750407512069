@import "../../styles/functions";

.portfolio {
  position: relative;

  width: rem(346px);
  height: rem(144.16px);
  margin-top: rem(180px);

  cursor: pointer;

  @media (max-width: $mobile) {
    margin-top: rem(122px);
    height: rem(98px);
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    display: block;

    width: rem(15px);
    height: rem(15px);

    border-radius: 50%;
    background: var(--color-white);

    @media (max-width: $mobile) {
      width: rem(12px);
      height: rem(12px);
    }
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    right: rem(-10px);

    width: rem(29px);
    height: rem(40.16px);
    display: block;

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../resources/icons/finger.svg");

    @media (max-width: $mobile) {
      width: rem(24px);
      height: rem(33.24px);

      right: rem(55px);
      bottom: rem(4px);
    }
  }
}

.text {
  position: absolute;
  left: rem(10px);
  bottom: rem(25px);

  font-family: "Malayalam", sans-serif;
  font-size: rem(30px);
  line-height: rem(18px);
  text-align: center;
  letter-spacing: 0.09em;

  color: var(--color-white);

  @media (max-width: $mobile) {
    left: 50%;
    transform: translateX(-50%);

    white-space: nowrap;
    font-size: rem(18px);
    line-height: rem(14px);
  }

  & span {
    display: inline-block;
    border-bottom: rem(1px) solid var(--color-white);
  }
}

.wrapper {
  @media (max-width: $mobile) {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.iframe {
  overflow: hidden;

  width: rem(1400px);
  height: rem(700px);

  @media (max-width: $mobile) {
    width: 100%;
    height: auto;
  }
}