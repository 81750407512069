@import "../../styles/functions";

.video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  width: 100%;
  height: 100%;
}

.wrap {
  position: relative;
  width: 100%;
  height: 100%;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: block;
    background-color: var(--color-black);
    opacity: .5;
  }

  & video {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    min-width: 100%;
    min-height: 100%;
  }
}