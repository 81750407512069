@import "../../styles/functions";

$firstbreakpoint: 1440px;
$secondbreakpoint: 1024px;

.body {
  background-color: var(--color-white);
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .main {
    color: var(--color-black);
    position: relative;
    box-sizing: content-box;
    width: 900px;
    padding-top: 100px;
    @media (max-width: $firstbreakpoint) {
      width: 720px;
    }
    @media (max-width: $secondbreakpoint) {
      width: 530px;
    }
    @media (max-width: $mobile) {
      width: 80%;
    }
    .header {
      text-transform: uppercase;
      font-family: "Malayalam";
      font-size: 60px;
      @media (max-width: $firstbreakpoint) {
        font-size: 48px;
      }
      @media (max-width: $secondbreakpoint) {
        font-size: 36px;
      }
      @media (max-width: $mobile) {
        font-size: 30px;
        padding-top: 30%;
      }
    }
    .text {
      margin-top: 16px;
      text-align: left;
      font-size: 30px;
      line-height: 145%;
      letter-spacing: -0.1px;
      @media (max-width: $firstbreakpoint) {
        font-size: 24px;
      }
      @media (max-width: $secondbreakpoint) {
        font-size: 18px;
      }
      .mobileOnly {
        display: none;
        @media (max-width: $mobile) {
          display: inline;
        }
      }
    }
    .links {
      display: flex;
      flex-direction: row;
      gap: 2%;
      margin-top: 71px;
      position: relative;
      z-index: 5;
      @media (max-width: $firstbreakpoint) {
        margin-top: 57px;
      }
      @media (max-width: $secondbreakpoint) {
        margin-top: 43px;
      }

      .link {
        border: 1px solid var(--color-black);
        border-radius: 100px;
        padding: 22px 38px;
        display: flex;
        flex-direction: row;
        gap: 35px;
        align-items: center;
        justify-content: center;
        @media (max-width: $firstbreakpoint) {
          padding: 18px 30px;
          gap: 28px;
        }
        @media (max-width: $secondbreakpoint) {
          padding: 14px 22px;
          gap: 21px;
        }
        @media (max-width: $mobile) {
          padding: 14px;
          gap: 15px;
        }
        &:hover {
          background-color: var(--color-yellow-green);
          border-color: var(--color-yellow-green);
        }

        .icon {
          width: 50px;
          aspect-ratio: 1;
          @media (max-width: $firstbreakpoint) {
            width: 40px;
          }
          @media (max-width: $secondbreakpoint) {
            width: 30px;
          }
        }
        .linkText {
          font-size: 26px;
          font-weight: 600;
          @media (max-width: $firstbreakpoint) {
            font-size: 21px;
          }
          @media (max-width: $secondbreakpoint) {
            font-size: 16px;
          }
        }
      }
      // just in order to perform PixelPerfect
      .toLeft {
        gap: 20px;
        padding-right: 50px;
        @media (max-width: $firstbreakpoint) {
          gap: 16px;
          padding-right: 40px;
        }
        @media (max-width: $secondbreakpoint) {
          gap: 12px;
          padding-right: 30px;
        }
        @media (max-width: $mobile) {
          padding-right: 14px;
          gap: 8px;
        }
      }
    }
    .arrow1 {
      position: absolute;
      top: 294px;
      left: 420px;
      scale: 1.22;
      @media (max-width: $firstbreakpoint) {
        scale: 0.98;
        top: 226px;
        left: 287px;
      }
      @media (max-width: $secondbreakpoint) {
        scale: 0.74;
        top: 160px;
        left: 157px;
      }
      @media (max-width: $mobile) {
        display: none;
      }
    }
    .arrow2 {
      position: absolute;
      top: 350px;
      left: 125px;
      scale: 1.08;
      z-index: 2;
      @media (max-width: $firstbreakpoint) {
        scale: 0.864;
        top: 293px;
        left: 50px;
      }
      @media (max-width: $secondbreakpoint) {
        scale: 0.65;
        top: 235px;
        left: -25px;
      }
      @media (max-width: $mobile) {
        display: none;
      }
    }
  }
}
