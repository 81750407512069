@import "../../styles/functions";

.what {
  padding-top: rem(87px);
  padding-bottom: rem(143px);

  @media (max-width: $mobile) {
    padding: rem(59px) rem(20px) rem(38px);
  }
}

.desc {
  margin-bottom: rem(105px);
  width: rem(985px);

  @media (max-width: $mobile) {
    margin-bottom: rem(63px);
    width: 100%;
    text-align: justify!important;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.divider {
  position: relative;

  margin: rem(40px) 0;
  display: block;
  height: rem(253px);
  width: rem(2px);

  background-color: var(--color-divider);

  @media (max-width: $mobile) {
    margin: rem(35px) 0 rem(30px);
    height: rem(50px);
  }

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    display: block;
    width: rem(7px);
    height: rem(7px);

    background-color: var(--color-divider);
    border-radius: 50%;
  }
}