@import "../../styles/functions";

.menu {
  position: absolute;
  right: rem(-357px);
  top: 0;
  bottom: 0;

  height: 100%;
  width: rem(357px);
  padding: rem(60px) rem(35px) rem(40px);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: var(--color-white);
  transition: 1s;

  @media (max-width: $mobile) {
    width: 100%;
    padding: rem(155px) rem(35px) rem(30px);
    right: -100%;
    overflow-y: scroll;
  }

  @media (max-width: $mobile) and (orientation: landscape) {
    position: relative;
    height: rem(600px);
  }

  &:after {
    content: "";
    position: absolute;
    left: rem(35px);
    top: rem(20px);

    width: rem(133px);
    height: rem(86px);
    display: none;

    background-image: url("../../resources/icons/dark-logo.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    @media (max-width: $mobile) {
      display: block;
    }
  }

  &.active {
    right: 0;
  }
}

.close {
  position: absolute;
  z-index: 10;
  top: rem(40px);
  right: rem(48px);

  width: rem(30px);
  height: rem(30px);
  display: block;

  cursor: pointer;
  background-image: url("../../resources/icons/close.svg");
  background-size: cover;
  background-repeat: no-repeat;
  filter: invert(1);

  @media (max-width: $mobile) {
    top: rem(30px);
    right: rem(25px);
  }
}

.wrap {
  display: block;
}
