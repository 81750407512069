@import "../../styles/functions";

.main {
  justify-content: center;
  height: 100vh;

  @media (max-width: $mobile) {
    height: rem(640px);
  }

  @media (max-width: $mobile) and (orientation:portrait) {
    height: 100vh;
    height: calc(var(--vh,1vh) * 100);
  }
}

.content {
  position: relative;

  margin-top: rem(-100px);

  @media (max-width: $mobile) {
    margin-top: 0;
  }
}

.title {
  display: flex;
  justify-content: center;

  font-family: 'Malayalam', sans-serif;
  font-size: rem(47px);
  line-height: rem(65px);
  color: var(--color-new-gray);
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0 rem(4px) rem(4px) rgba(0, 0, 0, 0.5);

  @media (max-width: $mobile) {
    margin-bottom: rem(20px);
    letter-spacing: 0.09em!important;

    font-size: rem(22px);
    line-height: rem(36px);
  }
}

.desc {
  width: rem(646px);
  display: flex;
  align-items: center;
  flex-direction: column;

  text-align: justify;

  @media (max-width: $mobile) {
    width: rem(256px);

    text-align: center!important;
  }
}

.title, .desc {
  text-shadow: 0 rem(4px) rem(4px) rgba(0, 0, 0, 0.5);
  letter-spacing: 0.07em;
}

.control {
  position: absolute;
  left: 50%;
  bottom: rem(57px);
  transform: translateX(-50%);

  width: rem(35px);
  height: rem(35px);

  background-image: url("data:image/svg+xml,%3Csvg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.5 0C7.82895 0 0 7.82887 0 17.5C0 27.1711 7.82886 35 17.5 35C27.1711 35 35 27.1711 35 17.5C35 7.82895 27.1711 0 17.5 0V0ZM17.5922 23.1645L10.0396 15.7959L11.329 14.4605L17.5922 20.5855L23.7172 14.3224L25.0526 15.6118L17.5922 23.1645Z' fill='%23E2E2E2'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  cursor: pointer;

  @media (max-width: $mobile) {
    bottom: rem(29px)
  }
}