@import "../../styles/functions";

.dots {
  position: fixed;
  z-index: 10;
  right: rem(60px);
  top: calc(50% + rem(28px));
  transform: translateY(-50%);

  display: flex;
  flex-direction: column;
  align-items: center;

  will-change: filter;
  filter: drop-shadow(0 0 0 var(--color-black));
}

.item {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(14px);
  height: rem(14px);
  margin-bottom: rem(9px);

  user-select: none;
  cursor: pointer;

  @media (max-width: $mobile) {
    display: none;
  }

  &.active {
    padding: rem(10px) 0;
    align-items: flex-start;
    height: rem(70px);
    width: rem(40px);
    margin-bottom: rem(30px);
    margin-top: rem(5px);

    border: rem(1px) solid gray;
    border-radius: rem(30px);
  }

  &:before {
    content: '';

    width: rem(8px);
    height: rem(8px);
    display: block;

    background: gray;
    border-radius: 50%;
    transition: .3s;
  }

  &:hover:before {
    background-color: var(--color-white);
  }
}

.top {
  position: absolute;
  top: rem(-30px);
  left: 50%;
  transform: translateX(-50%);

  display: inline-block;

  font-family: 'Malayalam', sans-serif;
  font-size: rem(14px);
  line-height: rem(14px);
  letter-spacing: 0.07em;
  color: gray;
  white-space: nowrap;

  border-bottom: rem(1px) solid gray;
  cursor: pointer;
  user-select: none;
  opacity: 0;
  transition: .3s;
  visibility: hidden;

  &:hover {
    color: var(--color-white);
  }

  @media (max-width: $mobile) {
    display: none;
  }
}

.contacts .top {
  opacity: 1;
  visibility: visible;
}