@import "../../styles/functions";

.mainPage {
  opacity: 0;
  transition: 1.2s;

  &.active {
    opacity: 1;
    transition: 1.2s;
  }
  background-color: var(--color-black);
}
