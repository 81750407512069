@import "../../styles/functions";

.services {
  padding: rem(89px) 0 rem(60px);

  @media (max-width: $mobile) {
    padding: rem(59px) rem(15px) rem(12px) rem(25px);
  }
}

.list {
  padding-top: rem(30px);
  display: flex;
  flex-direction: column;
  width: rem(1053px);

  @media (max-width: $mobile) {
    padding-top: rem(39px);
    width: 100%;
  }
}

.item {
  margin-bottom: rem(70px);

  animation-duration: 2s!important;

  @media (max-width: $mobile) {
    margin-bottom: rem(35px);
  }

}

.title {
  margin-bottom: rem(10px);

  font-family: 'Malayalam', sans-serif;
  font-size: rem(30px);
  line-height: rem(42px);
  letter-spacing: 0.09em;

  @media (max-width: $mobile) {
    font-size: rem(16px);
    line-height: rem(23px);
    text-align: center;
  }
}

.text {
  font-family: 'Circe', sans-serif;
  font-size: rem(23px);
  line-height: rem(34px);
  letter-spacing: 0.07em;
  text-align: justify!important;

  @media (max-width: $mobile) {
    font-size: rem(14px);
    line-height: rem(22px);
    letter-spacing: 0.09em;
  }
}